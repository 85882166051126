@import "../node_modules/react-widgets/styles.css";
@import "../node_modules/react-tooltip/dist/react-tooltip.min.css";
@import "../node_modules/react-notifications/lib/notifications.css";
.rw-list {
  padding: 0 !important;
}

.rw-list-option {
  border: none !important;
  padding: 0 !important;
}

div.rw-state-selected > div.llm-dropdown-option {
  filter: brightness(90%);
  color: white !important;
}

div.llm-dropdown-option:hover {
  text-decoration: underline;
}

.margin-left-auto {
  margin-left: auto;
}

.background-none {
  background: transparent;
}
