@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'react-select-search/style.css';
body {
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    color: #555;
    background: rgb(246,246,246);
    cursor: default;
}

.color-grey {
    color: rgb(85,85,85);
}

.color-sky-grey {
    color: rgb(166,168,181);
}

.form-control {
    border-radius: 4px;
}

.form-Auth {
    max-width: 400px;
    background: #fff;
    margin: 0 auto;
    padding: 40px;
}

.bg-primary {
    background-color: #CC0000 !important;
}

.btn {
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
}

.btn-primary {
    background: rgb(78,107,243);
    font-weight: 600;
    font-size: 14px;
    padding: 7px 25px;
}

.btn-secondary {
    font-weight: 600;
    font-size: 14px;
    padding: 7px 25px;
    background: rgb(85,85,85);
}

.btn-default {
    font-weight: 600;
    font-size: 14px;
    padding: 7px 25px;
}

.btn-default:hover {
    color: rgb(166,168,181);
}

.nav > .btn-light {
    font-weight: 600;
    font-size: 14px;
    padding: 7px 25px;
}

.btn-light {
    background: #fff;
    border: 1px solid rgb(203, 208, 226);
    padding: 7px 25px;
}

.btn-danger {
    background: rgb(214,130,137);
    border-color: rgb(214,130,137);
    padding: 7px 25px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.br-l-none {
    border-left: 0px;
}

.input-group-project-st {
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.input-group-text {
    background: transparent;
    border-right: transparent;
    padding-right: 10px;
}

.box-form {
    background: #fff;
    padding: 25px;
    max-width: 560px;
    margin: 20px auto;
}

.table-box {
    background: #fff;
    padding: 25px 15px;
}

.table-content {
    min-width: 320px;
    overflow-x: auto;
}

.table td, .table th {
    padding-left: 15px;
    padding-right: 15px;
}

.table {
    color: rgb(85,85,85);
}

.pagination-box > ul {
    justify-content: center !important;
    gap: 4px;
}

@property --select-search-background {
    syntax: "<color>";
    inherits: false;
    initial-value: #fff;
}

@property --select-search-text {
    syntax: "<color>";
    inherits: false;
    initial-value: #000;
}

@property --select-search-border {
    syntax: "<color>";
    inherits: false;
    initial-value: #eee;
}

@property --select-search-selected {
    syntax: "<color>";
    inherits: false;
    initial-value: #ddd;
}

@property --select-search-subtle-text {
    syntax: "<color>";
    inherits: false;
    initial-value: #111;
}

@property --select-search-highlight {
    syntax: "<color>";
    inherits: false;
    initial-value: #ddd;
}

.select-search-container { width: 100%; }

.cursor-pointer{ cursor: pointer; }

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select:has(.select-search-option:nth-last-child(n+1)) {
    display: block;
}

.hover-text-decoration-underline:hover {
    text-decoration: underline !important;
}

:root {
    --bs-border-width: 0;
}

.border-top-1 { border-top-width: 1px; }
.border-bottom-1 { border-bottom-width: 1px; }

.table-top-divider {
    border-top-width: 1px;
    border-top-color: #eee;
}

.bg-secondary-light {
    background-color: #838e97 !important;
}

#create_user_form input.form-control{
    margin-bottom:10px;
    border: 1px gray solid;
}

.navbar{
    position: sticky;
    top: 0;
    overflow: hidden;
    height: 60px !important;
}

.nav-item{
    margin-right: 10px;
}

.nav-link{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 30px;
}

 .nav-link.active{
    border-bottom: 1px solid white;
}

body {
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

.inner-content{
    overflow: auto;
    height: calc(100vh - 60px);
    padding-top: 5px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #f6f6f6;
}

.logout-button{
    height: 30px;
    background-color: white !important;
    color: black !important;
}

.logout-butto:hover{
    background-color: gray !important;
}

.ml-50{
    margin-left: 50px;
}

.type-selector{
    height: 56px !important;
}

.hidden{
    display: none;
}

.visible{
    display: block;
}

.search-alert-btn{
    height: 56px;
}